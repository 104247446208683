function Oplaty2015()
{
    return (
        <div className="main-content oplaty.html">
        <h2>Opłaty</h2>
        <p>Opłata rejestracyjna wynosi 125zł za osobę (tj. tyle samo, co w ubiegłym roku).</p>
        <br />
        <p>Organizatorzy są w stanie wesprzeć udział drużyn, mających problemy z uzyskaniem finansowania przez swoja macierzystą uczelnie. Na wniosek coacha jesteśmy w stanie zwolnić z opłaty wpisowej do jednej drużyny z każdej takiej uczelni.</p>

        <br />

        <h3>Numer konta do wpłat:</h3>
        <p>	
        Konto bankowe: BZ WBK SA O/WROCŁAW<br />
        Numer konta: 71 1090 2503 0000 0006 3000 0004<br />
        BIC/SWIFT: WBKPPLPP<br /></p>
        <br />
        <p>
        <strong>W tytule wpłaty należy podać: AMPPZ - opłata wpisowa; nazwa uczelni</strong>
        </p>
        <br />			
        </div>
    )
}


function Oplaty2018()
{
    return (
            <div className="main-content oplaty.html">
            <h2>Opłaty</h2>
            <p>Opłata rejestracyjna wynosi 125zł za osobę (tj. tyle samo, co w ubiegłym roku).</p>

            <br />
            <p><i>Niewykluczone, że organizatorzy będą w stanie wesprzeć udział drużyn, mających problemy z uzyskaniem finansowania przez swoja macierzystą uczelnię. Przypomnijmy, że w ubiegłym roku, na wniosek coacha byliśmy w stanie zwolnić z opłaty wpisowej jedną drużynę z każdej takiej uczelni.</i></p>

            <h3>Numer konta do wpłat:</h3>
            <p>
            Konto bankowe: BZ WBK SA O/WROCŁAW<br />
            Numer konta: 69 1090 2503 0000 0001 3319 5237<br />
            BIC/SWIFT: WBKPPLPP<br /></p>
            <br />
            <p>
            <strong>W tytule wpłaty należy podać: AMPPZ - opłata wpisowa; nazwa uczelni; e-mail kontaktowy</strong>
            </p>


            <h3 className="red-text-header">UWAGA!</h3>
            <div className="red-text"><strong>
            <p>Równocześnie z dokonaniem wpłaty, prosimy o przesłanie
                <sup >⁎</sup> na adres: <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a> <br />
                informacji odnośnie danych, na które należy wystawić fakturę za dokonaną wpłatę. W treści informacji prosimy o podanie:
            </p><ul>
            <li>Nazwy Uczelni </li>
            <li>Adresu Uczelni </li>
            <li>NIP'u Uczelni </li>
            </ul>
            <p></p>
            </strong>
            </div>
            <br />

            <p><i><sup>⁎</sup> Nieprzekazanie danych do wystawienia faktury wywołuje problemy o ponad wykładniczej złożoności związane ze zewidencjonowaniem dokonanej wpłaty w systemie finansowo-księgowym.  W skrajnych przypadkach może się to przyczynić do powstania problemów związanych ze startem w zawodach.
            </i></p>



            <br />
			</div>        
    )
}

function Oplaty(data)
{
    if (data.year==="2015") return Oplaty2015();
    return Oplaty2018();            
}

export default Oplaty;