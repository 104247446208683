function Zakwaterowanie2018()
{
    return (
        <div className="main-content zakwaterowanie.html">
        <h2>Zakwaterowanie</h2>
        <p>
        Na potrzeby AMPPZ 2018 w hotelach Premiere Classe Hotel Wrocław i Campanile Wrocław Centrum zlokalizowanych blisko Dworca Głównego PKP i Dworca Głównego PKS we Wrocławiu 
        zarezerwowano pulę pokoi w terminie 25-27 października 2018 r.
        </p>

        <br />

        <p><b>Ustalony koszt rezerwacji</b>:</p>

        <ul >

        <li> Premiere Classe Wrocław Centrum 

        <ul className="indent">
            <li>175 PLN/brutto/doba pokój 1-osobowy</li>
            <li>195 PLN/brutto/doba pokój 2-osobowy (twin)</li>
        </ul>
        </li><li> Campanile Wrocław Centrum
        <ul className="indent">
            <li>200 PLN/brutto/doba pokój 1-osobowy</li>
            <li>235 PLN/brutto/doba pokój 2-osobowy (twin)</li>
        </ul>

        </li>
        </ul>

        <p>Na hasło "AMPPZ 2018". 
            <br /><br />
            W cenę wliczone są śniadania.
            Goście aby otrzymać preferencyjne stawki proszeni są o podanie powyższego hasła.
        </p>

        <p>
            Jednocześnie informuję iż rezerwacje wymagają przedpłaty do 2 dni od otrzymania potwierdzenia. W innym wypadku będą anulowane.
            <br /><br />
            Rezerwacje mogą być dokonywane drogą mailową pod adresem: 
        </p>
        <ul className="indent">
            <li>Hotel Premiere Classe: <a href="mailto:wroclaw.centrum@premiereclasse.com">wroclaw.centrum@premiereclasse.com</a></li> 
            <li>Hotel Campanile: <a href="mailto:wroclaw.centrum@campanile.com">wroclaw.centrum@campanile.com</a></li> 
        </ul>
        lub pod numerem telefonu:
        <ul className="indent">
            <li>Premiere Classe: 71 783 0200</li>
            <li>Campanile: 71 783 0300</li>
        </ul>
    </div>    
    )
}

function Zakwaterowanie2017()
{
    return (
        <div className="main-content zakwaterowanie.html">
        <h2>Zakwaterowanie</h2>

        <p>Na potrzeby AMPPZ 2017 w hotelu Premiere Classe Hotel Wrocław zlokalizowanym blisko Dworca Głównego PKP i Dworca Głównego PKS we Wrocławiu 
        zarezerwowano pulę pokoi w terminie 26-28 października 2017 r.</p>
        <br />
        <p><b>Ustalony koszt rezerwacji</b>:</p>

        <ul>
        <li> Premiere Classe Wrocław Centrum
        <ul className="indent">
            <li>150 PLN/brutto/doba pokój 1-osobowy</li>
            <li>170 PLN/brutto/doba pokój 2-osobowy (twin)</li>
        </ul>

        </li>
        </ul>

        <p>Na hasło "AMPPZ 2017". <br />W cenę wliczone są śniadania.

        Goście aby otrzymać preferencyjne stawki proszeni są o podanie powyższego hasła.
        </p>
        <p>
        Jednocześnie informuję iż rezerwacje wymagają przedpłaty do 2 dni od otrzymania potwierdzenia. W innym wypadku będą anulowane.
        <br /><br />
        Rezerwacje mogą być dokonywane drogą mailową pod adresem: <a href="mailto:reservations.wroclaw.centrum@premiereclasse.com">reservations.wroclaw.centrum@premiereclasse.com</a> 
        lub pod numerem telefonu 71 78 0 351.
        </p>
		</div>
    )
}

function Zakwaterowanie2016()
{
    return (
        <div className="main-content zakwaterowanie.html">
        <h2>Zakwaterowanie</h2>

        <p>Na potrzeby AMPPZ 2015 w hotelach Premiere Classe Hotel Wrocław oraz Campanile Wrocław Centrum – zlokalizowanych blisko Dworca Głównego PKP i tymczasowego Dworca Głównego PKS we Wrocławiu – zarezerwowano pulę pokoi na hasło „AMPPZ 2016” w terminie 27-29 października 2016 r.</p>
        <br />
        <p><b>Ustalony koszt rezerwacji</b>:</p>

        <ul>
        <li> Premiere Classe Wrocław Centrum
        <ul className="indent">
            <li>145 PLN/doba pokój 1-osobowy</li>
            <li>165 PLN/doba pokój 2-osobowy (twin)</li>
        </ul>
        </li>

        <li>Campanile Wrocław Centrum
        <ul className="indent">
            <li>190 PLN/doba pokój 1-osobowy</li>
            <li>215 PLN/doba pokój 2-osobowy (twin)</li>
        </ul>
        </li>
        </ul>

        <p>W cenie pokoju zawarte jest śniadanie w formie szwedzkiego bufetu, podatek VAT oraz dostęp do Internetu.</p>
        <p>Parking dodatkowo płatny w cenie 35 PLN auto/doba.</p>
        <p>Wszystkie rezerwacje w preferencyjnej stawce wymagają pełnej przedpłaty w terminie do 3 dni od dokonania rezerwacji. </p>
        <p>Rezerwacje bez możliwości anulacji oraz zmian, w przypadku anulacji lub modyfikacji ze strony zamawiającego&nbsp;hotel&nbsp;ma prawo obciążyć gościa pełnym kosztem rezerwacji.</p>

        <br />
        <p><b>Rezerwacja mailowo w dziale rezerwacji:</b> <br />
        </p><ul className="indent">
            <li>Premiere Classe - <a href="mailto:reservations.wroclaw.centrum@premiereclasse.com">reservations.wroclaw.centrum@premiereclasse.com</a></li>
            <li>Campanile - <a href="mailto:reservations.wroclaw.centrum@campanile.com">reservations.wroclaw.centrum@campanile.com</a></li>
        </ul><br />

        lub telefonicznie od poniedziałku do piątku w godzinach 8.00 - 18.00 pod numerem telefonu: 717 830 350

        <p></p>

        <br />
        <p><b>Wysyłając rezerwacje pokojów prosimy o wpisanie w treści emaila:</b></p>
        <ul className="indent">
            <li>Hasła: "AMPPZ 2016"</li>
            <li>Numerut  rezerwacji: </li>
                <ul>
                    <li>Campanille: <strong>76855361</strong></li>
                    <li>Premiere Classe: <strong>7685361</strong></li>
                </ul>
            <li>Terminu rezerwacji</li>
            <li>Liczby pokoi</li>
            <li>Liczby osób</li>
            <li>Nazwiska gości z podziałem na pokoje</li>
        </ul>

        <br />

        <p>Do Kampusu Grunwaldzkiego można dojechać liniami autobusowymi <strong>145</strong> i <strong>146</strong> </p><p> (Dworzec Autobusowy --&gt; Most Grunwaldzki)</p>
        <br />
		</div>        
    )
}

function Zakwaterowanie2015()
{
    return (
            <div className="main-content zakwaterowanie.html">
            <h2>Zakwaterowanie</h2>

            <p>Na potrzeby AMPPZ 2015 w hotelu Premiere Classe Hotel Wrocław – zlokalizowanym blisko Dworca Głównego PKP i tymczasowego Dworca Głównego PKS we Wrocławiu – zarezerwowano pulę pokoi na hasło „AMPPZ 2015” w terminie 23-25 października 2015 r.</p>
            <br />
            <p><b>Ustalony koszt rezerwacji</b>:</p>
            <ul className="indent">
                <li>165 PLN za pokój dwuosobowy (twin) ze śniadaniem za jedną dobę, płatność przelewem na nr konta podany w procesie rezerwacji</li>
                <li>151 PLN + 20 PLN (śniadanie) za jedną dobę, płatność w dniu przyjazdu</li>
            </ul>

            <br />
            <p><b>Rezerwacji proszę dokonywać pod adresem:</b> <a href="mailto:groups.wroclaw.centrum@campanile.com">groups.wroclaw.centrum@campanile.com</a> </p>
            <br />
            <p><b>Wysyłając rezerwacje pokojów prosimy o wpisanie w treści emaila:</b></p>
            <ul className="indent">
                <li>Hasła: "AMPPZ 2015"</li>
                <li>Terminu rezerwacji</li>
                <li>Liczby pokojów</li>
                <li>Liczby osób</li>
                <li>Nazwiska gości z podziałem na pokoje</li>
            </ul>

            <br />

            <p>Do Kampusu Grunwaldzkiego można dojechać liniami autobusowymi <strong>145</strong> i <strong>146</strong> </p><p> (Dworzec Autobusowy --&gt; Most Grunwaldzki)</p>
            <br />
            <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5011.61247585868!2d17.02968036782036!3d51.09358331053994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x652fefd13671d8a0!2sPremiere+Classe+Wroc%C5%82aw+Centrum!5e0!3m2!1spl!2spl!4v1442618779734" height="450" frameBorder="1" allowFullScreen=""></iframe>
			</div>
    )
}



function Zakwaterowanie(data)
{
    if (data.year==="2015") return Zakwaterowanie2015();
    if (data.year==="2016") return Zakwaterowanie2016();
    if (data.year==="2017") return Zakwaterowanie2017();
    return Zakwaterowanie2018();    
    
}

export default Zakwaterowanie;