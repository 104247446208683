function Rozwiazania2018()
{
    return (
        <div className="main-content rozwiazania.html">
        <h2> Rozwiązania Zadań </h2>
        <p>
        <iframe title='rozwiazania' src="/ViewerJS/index.html#/files/prez.pdf" width="400" height="500" allowFullScreen="" webkitallowfullscreen=""></iframe>
        </p>
        <ul>
        <li><a target="_blank" href="/files/prez.pdf">Rozwiązania (pdf)</a></li>
        </ul>
		</div>        
    )
}

function Rozwiazania2017()
{
    return (
        <div className="main-content rozwiazania.html">
        <h2> Rozwiązania Zadań </h2>
        <p>
        <iframe title="omowienie" src="/ViewerJS/index.html#/files/omowienie.pdf" width="400" height="500" allowfullscreen="" webkitallowfullscreen=""></iframe>
        </p><ul>
        <li><a target="_blank" href="../files/omowienie.pdf">Rozwiązania (pdf)</a></li>
        </ul>
        <p></p>
        </div>
    )
}

function Rozwiazania2016()
{
    return (
        <div className="main-content rozwiazania.html">
        <h2> Rozwiązania Zadań </h2>
        <p>
        <iframe title="omowienie" src="/ViewerJS/index.html#/files/omowienie2016.pdf" width="400" height="500" allowfullscreen="" webkitallowfullscreen=""></iframe>
        </p><ul>
        <li><a target="_blank" href="../files/omowienie2016.pdf">Rozwiązania (pdf)</a></li>
        </ul>
        <p></p>			
        </div>
    )
}

function Rozwiazania2015()
{
    return (
        <div className="main-content rozwiazania.html">
        <h2> Rozwiązania Zadań </h2>
        <p> </p><ul><li><a href="../files/rozwiazania.pdf">Rozwiązania (pdf)</a></li></ul><p></p>
        <h3>Wideo-omówienia zadań</h3>
        <p>
        <iframe title='film' width="560" height="315" src="https://www.youtube.com/embed/4Nkb46YDieQ?list=PLZMkHz1BBdtcqjSF9KZxbBgiZcJVIQUkO" frameBorder="0" allowFullScreen=""></iframe></p>
        <br /><br />
        <p>Ciekawe opracowanie zadania G na blogu Pawła Gawrychowskiego: <a href="https://fajnezadania.wordpress.com/2015/10/28/gra-planszowa/">https://fajnezadania.wordpress.com/2015/10/28/gra-planszowa/ </a></p>			</div>    
    )
}


function Rozwiazania(data)
{
    if (data.year==="2015") return Rozwiazania2015();
    if (data.year==="2016") return Rozwiazania2016();
    if (data.year==="2017") return Rozwiazania2017();
    return Rozwiazania2018();
}

export default Rozwiazania;
