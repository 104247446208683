import backgroundfooter from "./img/banner-bottom.jpg"

function Footer()
{
return (
	<footer className='footer'>
		<div className='foot-right'><a href="https://icpc.baylor.edu/" target="_blank" rel="noreferrer"> <img src="/img/icpc-logo.png" alt='ICPC' /> </a></div>
		<div className='foot-mid'
		style={{ backgroundImage: `url(${backgroundfooter})` }}
		/>
		<div className='foot-left'><a href="http://ii.uni.wroc.pl/" target="_blank" rel="noreferrer"> <img src="/img/logoii.png" alt='Instytut Informatyki UWr' /></a></div>
	</footer>
)
}

export default Footer;