function Miejsce()
{
    return (
            <div className="main-content miejsce.html">
            <h2>Miejsce Zawodów</h2>
            <p>
            Instytut Informatyki Uniwersytetu Wrocławskiego <br />
            ul. Fryderyka Joliot-Curie 15 <br />
            Wrocław <br />
            </p>
            <br />

            <iframe title='hotel' scrolling="no" marginHeight="0" marginWidth="0" src="http://maps.google.com/maps?f=q&amp;hl=en&amp;geocode=&amp;q=Poland+Wroc%C5%82aw+Joliot-Curie+15&amp;sll=51.111282,17.052069&amp;sspn=0.009672,0.018797&amp;ie=UTF8&amp;s=AARTsJrjLxD56V8PjR5HcLZDUinEpVnv6w&amp;ll=51.110474,17.042799&amp;spn=0.032332,0.051498&amp;z=14&amp;iwloc=addr&amp;output=embed" frameBorder="1px" height="600" width="600"></iframe>

			</div>
    )
}

export default Miejsce;
