function Facebook()
{
    return (
        <div className="fb">
            <div className="fb-page fb_iframe_widget" 
            data-href="https://www.facebook.com/amppz.contest/?fref=ts" 
            data-width="500" data-height="70" data-small-header="true" 
            data-adapt-container-width="true" data-hide-cover="false" 
            data-show-facepile="false" data-show-posts="false" fb-xfbml-state="rendered" 
            fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=475&amp;height=70&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Famppz.contest%2F%3Ffref%3Dts&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=false&amp;show_posts=false&amp;small_header=true&amp;width=500">
            <span>
            <iframe name="f2f8de42e95e09" width="500px" height="70px" data-testid="fb:page Facebook Social Plugin" 
            title="fb:page Facebook Social Plugin" frameBorder="0" allowtransparency="true" allowFullScreen={true} scrolling="no" allow="encrypted-media" 
            src="https://www.facebook.com/v2.5/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df32081394b747c8%26domain%3Damppz.ii.uni.wroc.pl%26origin%3Dhttp%253A%252F%252Famppz.ii.uni.wroc.pl%252Ffacf1b9db710b8%26relation%3Dparent.parent&amp;container_width=475&amp;height=70&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Famppz.contest%2F%3Ffref%3Dts&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=false&amp;show_posts=false&amp;small_header=true&amp;width=500" 
            className="fcb">
            </iframe>
            </span>
            </div>
        </div>    
    )
}

export default Facebook;
