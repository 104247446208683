function Ranking2015()
{
    return (
        <div className="main-content ranking.html">
        <h2> Ranking </h2>

        <p>Ranking dostępny jest pod adresem: <a href="http://solve.edu.pl/~amppz/chartcode/ranking.html" target="blank">http://solve.edu.pl/~amppz/chartcode/ranking.html</a>.</p>
        <br />
        <p>Wizualizacja przebiegu zawodów:</p>
        <iframe title="wizualizacja przebiegu zawodow" width="560" height="315" src="https://www.youtube.com/embed/dhmPnPMg9Lo" frameborder="0" allowfullscreen=""></iframe>			
        </div>
    )    
}

function Ranking2016()
{
    return (
        <div className="main-content ranking.html">
        <h2> Ostateczny Ranking </h2>

        <ul>
        <li>Ranking
        <ul>
        <li><a href="/ranking2016/ranking.html" target="_blank">Klasyfikacja oficjalna Mistrzostw</a></li>
        <li><a href="/ranking2016/ranking-open.html" target="_blank">Klasyfikacja w kategorii Open</a> </li>
        </ul>
        </li>
        </ul>
        <br />
        <p>Wizualizacja przebiegu zawodów:</p>
        <iframe title="wizualizacja przebiegu zawodow" width="560" height="315" src="https://www.youtube.com/embed/JxpWjI46DyM" frameBorder="0" allowFullScreen=""></iframe>
        </div>    
        )    
}

function Ranking2017()
{
    return (
        <div className="main-content ranking.html">
        <h2> Ostateczny Ranking </h2>
        <ul>
        <li>Ranking
        <ul>
        <li><a href="/ranking2017/ranking.html" target="_blank">Klasyfikacja oficjalna Mistrzostw</a></li>
        <li><a href="/ranking2017/ranking-open.html" target="_blank">Klasyfikacja w kategorii Open</a> </li>
        </ul>
        </li>
        </ul>
        <br />
        Wizualizacja przebiegu zawodów:<p></p>
        <iframe title="wizualizacja przebiegu zawodow" width="560" height="315" src="https://www.youtube.com/embed/hlxQ6cYLr5Q" frameBorder="0" allowFullScreen=""></iframe>
		</div>
    )    
}

function Ranking2018()
{
    return (
        <div className="main-content ranking.html">
        <h2> Ostateczny Ranking </h2>
        <ul>
        <li>Ranking

        <ul>
        <li><a href="/ranking2018/ranking.html" target="_blank">Klasyfikacja oficjalna Mistrzostw</a></li>
        <li><a href="/ranking2018/ranking-open.html" target="_blank">Klasyfikacja w kategorii Open</a> </li>
        </ul>
        </li>
        </ul>

        <br />
        <p>Wizualizacja przebiegu zawodów:</p>
        <iframe title='przebieg zawodow' width="720" height="480" src="https://www.youtube.com/embed/dOTEw_aQjRY" frameBorder="0" allowFullScreen=""></iframe>        
		</div>
    )
}

function Ranking(data)
{
    if (data.year==="2015") return Ranking2015();
    if (data.year==="2016") return Ranking2016();
    if (data.year==="2017") return Ranking2017();
    return Ranking2018();          
}

export default Ranking;
