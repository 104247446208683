
function Sponsors2018()
{
    return (    
		<aside className='main-sponsors'>
			<h3> Partner strategiczny:</h3>
			<a target='_blank' rel='noreferrer' href="http://www.pkobp.pl"><img src="/img/pko-logo.png" alt="PKO BP" /></a>
			<br />
			<h3>Partner <br />platynowy:</h3>
			<br />
			<a target='_blank' rel='noreferrer' href="http://www.itkontrakt.pl"><img src="/img/itkontrakt.png" alt="it KONTRAKT" /></a>

			<h3>Sponsorzy:</h3>
			<a target='_blank' rel='noreferrer' href="https://services.google.com/fb/forms/google-amppz18/"><img src="/img/google.png" alt="Google" /></a>
			<a target='_blank' rel='noreferrer' href="https://www.facebook.com/careers/"><img src="/img/facebook.png" alt="Facebook" /></a>
			<a target='_blank' rel='noreferrer' href="https://www.nokia.com"><img src="/img/nokia.png" alt="Nokia" /></a>
			<a target='_blank' rel='noreferrer' href="https://www.kariera.axit.pl"><img src="/img/axit.png" alt="Axit" /></a>
			<a target='_blank' rel='noreferrer' href="https://tensquaregames.com"><img src="/img/tensquare.png" alt="Ten Square Games" /></a>
			<a target='_blank' rel='noreferrer' href="http://poland.ppg.com"><img src="/img/ppg.jpg" alt="PPG" /></a>

			<br /><br />
			<h3>Partner:</h3>
			<a target='_blank' rel='noreferrer' href="http://araw.pl"><img src="/img/araw.png" alt="Agencja Rozwoju Aglomeracji Wrocławskiej" /></a>
		</aside>
    )
}

function Sponsors2017()
{
    return (    
				<aside className='main-sponsors'>
				<h3> Partner strategiczny:</h3>
                <a target="_blank" rel="noreferrer" href="http://www.pkobp.pl"><img src="/img/pko-logo.png" alt="PKO BP" /></a>
				<br />
				<h3>Partner <br />platynowy:</h3>
				<a target="_blank" rel="noreferrer" href="http://www.itkontrakt.pl"><img src="/img/itkontrakt.png" alt="it KONTRAKT" /></a>
				<br />
				<h3>Sponsorzy:</h3>

				<a target="_blank" rel="noreferrer" href="http://fundacjapkobp.pl"><img src="/img/pkofundacja.png" alt="PKO Fundacja" /></a>
				<a target="_blank" rel="noreferrer" href="http://www.facebook.com/careers/"><img src="/img/facebook.png" alt="Facebook" /></a>
				<a target="_blank" rel="noreferrer" href="http://kariera.axit.pl"><img src="/img/axit.png" alt="Axit" /></a>
				<a target="_blank" rel="noreferrer" href="http://nokia.com"><img src="/img/nokia.png" alt="Nokia" /></a>
				<a target="_blank" rel="noreferrer" href="http://tensquaregames.com"><img src="/img/tensquare.png" alt="Ten Square Games" /></a>
				<a target="_blank" rel="noreferrer" href="http://www.fingo.pl/"><img src="/img/logo-fingo.svg" alt="Fingo" /></a>

				<br /><br />
				<h3>Partner:</h3>
				<a target="_blank" rel="noreferrer" href="http://araw.pl"><img src="/img/araw.png" alt="Agencja Rozwoju Aglomeracji Wrocławskiej" /></a>
				</aside>	
			)
}

function Sponsors2016()
{
    return (   
<aside class="main-sponsors">
				<h3> Partner strategiczny:</h3>
				<a target="_blank" rel="noreferrer" href="http://www.pkobp.pl"><img src="/img/pko-logo.png" alt="PKO BP" /></a>

				<br /><br />
				<h3>Sponsorzy:</h3>
				<a target="_blank" rel="noreferrer" href="http://fundacjapkobp.pl"><img src="/img/pkofundacja.png" alt="PKO Fundacja" /></a>
				<a target="_blank" rel="noreferrer" href="http://www.facebook.com/careers/"><img src="/img/facebook.png" alt="Facebook" /></a>
				<a target="_blank" rel="noreferrer" href="https://www.google.com/about/careers/"><img src="/img/google.png" alt="Google" /></a>
				<a target="_blank" rel="noreferrer" href="http://kariera.axit.pl"><img src="/img/axit.png" alt="Axit" /></a>
				<a target="_blank" rel="noreferrer" href="http://nokia.com"><img src="/img/nokia.png" alt="Nokia" /></a>
				<a target="_blank" rel="noreferrer" href="http://www.capgeminisoftware.pl"><img src="/img/capgemini.jpg" alt="Capgemini.jpg" /></a>
				<a target="_blank" rel="noreferrer" href="http://tensquaregames.com"><img src="/img/tensquare.png" alt="Ten Square Games" /></a>

				<br /><br />
				<h3>Partner:</h3>
				<a target="_blank" rel="noreferrer" href="http://araw.pl"><img src="/img/araw.png" alt="Agencja Rozwoju Aglomeracji Wrocławskiej" /></a>
			</aside>	)
}

function Sponsors2015()
{
    return (    
			<aside className="main-sponsors">
				<h3> Sponsorzy: </h3>
				<a target="_blank" rel="noreferrer" href="http://facebook.com"><img src="/img/facebook.png" alt="Facebook" /></a>
				<a target="_blank" rel="noreferrer" href="http://tensquaregames.com/"><img src="/img/tensquare.png" alt="Ten Square Games" /></a>
				<a target="_blank" rel="noreferrer" href="http://nokia.com"><img src="/img/nokia.png" alt="Nokia" /></a>
				<a target="_blank" rel="noreferrer" href="http://dolby.com"><img src="/img/dolby.png" alt="Dolby" /></a>
				<a target="_blank" rel="noreferrer" href="http://google.com"><img src="/img/google.png" alt="Google" /></a>
				<a target="_blank" rel="noreferrer" href="http://credit-suisse.com"><img src="/img/creditsuisse.png" alt="Credit Suisse" /></a>
				<a target="_blank" rel="noreferrer" href="http://fundacjapkobp.pl"><img src="/img/pkofundacja.png" alt="PKO Fundacja" /></a>
				<br /><br />
				<h3>Partner strategiczny:</h3>
				<a target="_blank" rel="noreferrer" href="http://araw.pl"><img src="/img/araw.png" alt="Agencja Rozwoju Aglomeracji Wrocławskiej" /></a>
			</aside>	)
}



function Sponsors(data)
{
    if (data.year==="2015") return Sponsors2015();
    if (data.year==="2016") return Sponsors2016();
    if (data.year==="2017") return Sponsors2017();
    return Sponsors2018();
}

export default Sponsors;