function Tresci2018()
{
    return (
        <div className="main-content tresci.html">
            <h2>Treści zadań</h2>
        <ul>
        <li><a href="/files/amppz_problemset_pl_2018.pdf">Zadania (pdf)</a></li>
        <li><a href="/files/amppz_problemset_en_2018.pdf">Zadania - wersja angielska (pdf)</a></li>
        </ul>
        </div>    
    )
}

function Tresci2017()
{
    return (
        <div className="main-content tresci.html">
        <h2>Treści zadań</h2>
        <ul>
        <li><a href="/files/amppz_problemset_pl_2017.pdf">Zadania (pdf)</a></li>
        <li><a href="/files/amppz_problemset_en_2017.pdf">Zadania - wersja angielska (pdf)</a></li>
        </ul>
		</div>        
    )
}

function Tresci2016()
{
    return (
        <div className="main-content tresci.html">
        <h2>Treści zadań</h2>
        <ul>
        <li><a href="/files/amppz_problemset_pl_2016.pdf">Zadania (pdf)</a></li>
        <li><a href="/files/amppz_problemset_en_2016.pdf">Zadania - wersja angielska (pdf)</a></li>
        </ul>			
        </div>
    )
}
function Tresci2015()
{
    return (
        <div className="main-content tresci.html">
        <h2>Treści zadań</h2>
        <ul>
        <li><a href="/files/amppz_problemset_pl_2015.pdf">Zadania (pdf)</a></li>
        <li><a href="/files/amppz_problemset_en_2015.pdf">Zadania - wersja angielska (pdf)</a></li>
        </ul>
        </div>
    )
}

function Tresci(data)
{
    if (data.year==="2015") return Tresci2015();
    if (data.year==="2016") return Tresci2016();
    if (data.year==="2017") return Tresci2017();
    return Tresci2018();
}

export default Tresci;
