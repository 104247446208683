import background from "./img/banner-ii.jpg"
import './main.css';

function MainHeader(data)
{
    return (
    <div className='header'>

    <div className='content'
        style={{ backgroundImage: `url(${background})` }}
    >
    <aside className='competitions-name'>
    <h1>
    Akademickie Mistrzostwa Polski <br />w Programowaniu Zespołowym</h1>
    Wrocław, {data.text}
    </aside>
    </div>
    </div>
    
    )
}

export default MainHeader;