function Kontakt()
{
    return (
        <div className="main-content kontakt.html">
        <h2>Kontakt</h2>
        <p>
        Akademickie Mistrzostwa w Programowaniu Zespołowym <br />
        Instytut Informatyki <br />
        Uniwersytet Wrocławski <br />
        ul. Joliot-Curie 15, 50-383 Wrocław <br /><br />

        email: <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a>
        </p>			
        </div>
    )
}

export default Kontakt;