function Regulamin2018()
{
    return (
        <div className="main-content regulamin.html">
        <h2>Regulamin zawodów </h2>

        <iframe src="/files/regulamin2018.html" title="regulamin 2018" />
        <ul>
        <li> <a href="/files/regulamin2018.pdf" target="_blank"> Regulamin (PDF)</a> </li> 
        </ul>
        <br />
        <h3>Dla drużyn sponsorskich</h3>
        <iframe title='regulamin druzyn sponsorskich' src="/files/sregulamin2018.html"></iframe>

        <ul>
        <li> <a href="/files/sregulamin2018.pdf" target="_blank"> Regulamin dla drużyn sponsorskich (PDF)</a> </li> 
        </ul>
        </div>
    )
}

function Regulamin2017()
{
    return (
        <div className="main-content regulamin.html">
        <h2>Regulamin zawodów </h2>
        <iframe src="/files/regulamin2017.html" title="regulamin 2017" />
        <ul>
        <li><a href="/files/regulamin2017.pdf" target="_blank"> Regulamin (PDF)</a> </li> 
        </ul>
        </div>
    )
}

function Regulamin2016()
{
    return (
        <div className="main-content regulamin.html">
        <h2>Regulamin zawodów </h2>
        <iframe src="/files/regulamin2016.html" title="regulamin 2016" />
        <ul>
        <li><a href="/files/regulamin2016.pdf" target="_blank"> Regulamin (PDF)</a> </li> 
        </ul>
        </div>
    )
}

function Regulamin2015()
{
    return (
        <div className="main-content regulamin.html">
        <h2>Regulamin zawodów </h2>
        <iframe src="/files/regulamin2015.html" title="regulamin 2015" />
        <ul>
        <li><a href="/files/regulamin2015.pdf" target="_blank"> Regulamin (PDF)</a> </li> 
        </ul>
        </div>
    )
}

function Regulamin(data)
{
    console.log("dd:" +data.year);
    if (data.year==="2015") return Regulamin2015();
    if (data.year==="2016") return Regulamin2016();
    if (data.year==="2017") return Regulamin2017();
    return Regulamin2018();    
}


export default Regulamin;
