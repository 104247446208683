function Harmonogram2018()
{
    return (
            <div className="main-content harmonogram.html">
            <h2>Wstępny Harmonogram Zawodów</h2>

            <p>Do <b>15 X 2018</b> – zgłoszenie liczby drużyn (i opiekunów)</p>
            <p> <b>15 X 2018</b> - informacja o liczbie przyjętych drużyn</p>
            <p>Do <b>20 X 2018</b> – imienne zgłoszenie członków drużyn</p>
            <p>Do <b>20 X 2018</b> – dokonanie wpłaty za uczestnictwo w zawodach</p>

            <p>
            </p>

            <h3>Czwartek, 25 X 2018</h3>

            <ul>
                <li>
                <p>Przyjazd</p>
                </li>
            </ul>

            <h3>Piątek, 26 X 2018</h3>

            <ul>
                <li>
                <p>8.15 : 9.45 – Rejestracja</p>
                </li>
                <li>
                <p>10.00 – Uroczystość otwarcia (s. 25)</p>
                </li>
                <li>
                <p>10.15 – Wykłady przedstawicieli sponsorów (s. 25)</p>

                <ul className="indent">
                    <li>
                    <p>10.15 : 10.35 – PKO BP - TBA</p>
                    </li>
                    <li>
                    <p>10:35 : 10:55 – IT Kontrakt - "Z niczego, do użytecznych danych"</p>
                    </li>
                    <li>
                    <p>10:55 : 11:15 – Google - "Kubernetes Demo"</p>
                    </li>
                    <li>
                    <p>11:15 : 11:35 – Nokia - "Różnice pomiędzy 4G i 5G"</p>
                    </li>
                    <li>
                    <p>11.35 : 11.45 – Przerwa</p>
                    </li>
                    <li>
                    <p>11:45 : 12:05 – Facebook - "Social Hash"</p>
                    </li>
                    <li>
                    <p>12:05 : 12:25 – PPG - "Programowanie funkcyjne (roboczy)"</p>
                    </li>
                    <li>
                    <p>12:25 : 12:45 – Axit - "In the world full of bugs explore like Bear Grylls"</p>
                    </li>
                    <li>
                    <p>12:45 : 13:05 – Ten Square Games - "Szyfrowanie w przeglądarkach internetowych"</p>
                    </li>
                </ul>

                </li>

                <li>
                <p>13.05 : 14.30 – Obiad (parter II UWr)</p>
                </li>
                <li>
                <p>14.30 : 14.55 – Zasady organizacji zawodów, sprawy techniczne (s. 25)</p>
                </li>
                <li>
                <p>15.15 : 17.00 – Zawody próbne (pracownie, I p. II UWr)</p>
                </li>
                <li>
                <p>17:00 : 18.00 – Możliwość spotkania z przedstawicielami sponsorów (hall, parter II UWr)</p>
                </li>
                <li>
                <p>18:00 – Kolacja (I p. II UWr)
                </p></li>
            </ul>

            <h3>Sobota, 27 X 2018</h3>

            <ul>
                <li>
                <p>8.30 – Przyjazd zespołów</p>
                </li>
                <li>
                <p>8.45 – Last minute announcements</p>
                </li>
                <li>
                <p>9.30 : 14.30 – Zawody (pracownie, I p. II UWr)</p>
                </li>
                <li>
                <p>14.30 : 16.00 – Czas wolny</p>
                </li>
                <li>
                <p>16.00 : 17.00 – Omówienie zadań (s. 25)</p>
                </li>
                <li>
                <p>17.00 – Uroczystość zakończenia (s. 25)</p>
                </li>
                <li>
                <p>18.00 – Pizza Party (I p. II UWr)</p>
                </li>
            </ul>

 
			</div>    
        )
}

function Harmonogram2017()
{
    return (
                <div className="main-content harmonogram.html">
                <h2>Wstępny Harmonogram Zawodów</h2>

                <p>Do <b>15 X 2017</b> – zgłoszenie liczby drużyn (i opiekunów)</p>

                <p> <b>15 X 2017</b> - informacja o liczbie przyjętych drużyn</p>

                <p>Do <b>20 X 2017</b> – imienne zgłoszenie członków drużyn</p>

                <p>Do <b>20 X 2017</b> – dokonanie wpłaty za uczestnictwo w zawodach</p>

                <p>
                </p>

                <h3>Czwartek, 26 X 2017</h3>

                <ul>
                    <li>
                    <p>Przyjazd</p>
                    </li>
                </ul>

                <h3>Piątek, 27 X 2017</h3>

                <ul>
                    <li>
                    <p>8.15 : 9.45 – Rejestracja</p>
                    </li>
                    <li>
                    <p>10.00 – Uroczystość otwarcia (s. 25)</p>
                    </li>
                    <li>
                    <p>10.15 – Wykłady przedstawicieli sponsorów (s. 25)</p>

                    <ul className="indent">
                        <li>
                        <p>10.15 : 10.40 – PKO BP - Krzysztof Kuzara, dyrektor Biura Rozwoju Oprogramowania - "Czy bank to już firma IT?"</p>
                        </li>
                        <li>
                        <p>10:40 : 11:05 – IT Kontrakt - Łukasz Olbromski - "Historia znikających danych"</p>
                        </li>
                        <li>
                        <p>11:05 : 11:30 – Nokia - Miłosz Warzecha - "Inspirujący wstęp do metaprogramowania"</p>
                        </li>
                        <li>
                        <p>11.30 : 11.45 – Przerwa</p>
                        </li>
                        <li>
                        <p>11:45 : 12:10 – Facebook - Simon Whitaker - Engineering manager, Source Control - "Merge or Rebase? Source Control at Facebook. Developer Workflows to Unleash Maximum Productivity"</p>
                        </li>
                        <li>
                        <p>12:10 : 12:35 – Ten Square Games - Janusz Dziemidowicz - "Jak (nie) używać narzędzi kryptograficznych"</p>
                        </li>
                    </ul>

                    </li>

                    <li>
                    <p>13.00 : 14.30 – Obiad (parter IIUWr)</p>
                    </li>
                    <li>
                    <p>14.30 : 14.55 – Zasady organizacji zawodów, sprawy techniczne (s. 25)</p>
                    </li>
                    <li>
                    <p>15.15 : 17.00 – Zawody próbne (pracownie, Ip. IIUWr)</p>
                    </li>
                    <li>
                    <p>17:00 : 18.00 – Możliwość spotkania z przedstawicielami sponsorów (hall, parter IIUWr)</p>
                    </li>
                    <li>
                    <p>18:00 – Kolacja (I p. IIUWr)</p>

                    </li>
                </ul>

                <h3>Sobota, 28 X 2017</h3>

                <ul>
                    <li>
                    <p>8.30 – Przyjazd zespołów</p>
                    </li>
                    <li>
                    <p>8.45 – Last minute announcements</p>
                    </li>
                    <li>
                    <p>9.30 : 14.30 – Zawody (pracownie, Ip. IIUWr)</p>
                    </li>
                    <li>
                    <p>14.30 : 16.00 – Czas wolny</p>
                    </li>
                    <li>
                    <p>16.00 : 17.00 – Omówienie zadań (s. 25)</p>
                    </li>
                    <li>
                    <p>17.00 – Uroczystość zakończenia (s. 25)</p>
                    </li>
                    <li>
                    <p>18.00 – Pizza Party (Ip. IIUWr)</p>
                    </li>
                </ul>
			</div>        
            )
}

function Harmonogram2016()
{
    return (
        <div className="main-content harmonogram.html">
        <h2>Wstępny Harmonogram Zawodów</h2>

        <p>Do <b>9 X 2016</b> – zgłoszenie liczby drużyn (i opiekunów)</p>
        <p> <b>12 X 2016</b> - informacja o liczbie przyjętych drużyn</p>
        <p>Do <b>13 X 2016</b> – imienne zgłoszenie członków drużyn</p>
        <p>Do <b>16 X 2016</b> – dokonanie wpłaty za uczestnictwo w zawodach</p>
        <p>
        </p>

        <h3>Czwartek, 27 X 2016</h3>

        <ul>
            <li>
            <p>Przyjazd</p>
            </li>
        </ul>

        <h3>Piątek, 28 X 2016</h3>

        <ul>
            <li>
            <p>8.15 : 9.45 – Rejestracja</p>
            </li>
            <li>
            <p>10.00 – Uroczystość otwarcia (s. 25)</p>
            </li>
            <li>
            <p>10.15 – Wykłady przedstawicieli sponsorów (s. 25)</p>
                <ul className="indent">
                <li>
                <p>10.15 : 10.25 – PKO BP - Barbara Antoniszyn</p>
                </li>
                <li>
                <p>10:25 : 10:50 – NOKIA - Kamil Szatkowski i Łukasz Ziobroń - "Modern C++ Tips &amp; Tricks"</p>
                </li>
                <li>
                <p>10:50 : 11:15 – CapGemini - Damian Jarmużek - "Modularność w Javie 9"</p>
                </li>
                <li>
                <p>11.15 : 11.30 – Przerwa</p>
                </li>
                <li>
                <p>11:30 :  11:55 – Facebook - Wojciech Lis - "Mobile Device Lab"</p>
                </li>
                <li>
                <p>11:55 : 12:20 – Google - Krzysztof Grygiel - "Managed Cloud Infrastructure"</p>
                </li>
                <li>
                <p>12:20 : 12:45 – AXIT - Natalia Ziemianowicz - "UX dla Programistów"</p>
                </li>
            </ul>
            </li>

            <li>
            <p>13.00 : 14.30 – Obiad (parter IIUWr)</p>
            </li>
            <li>
            <p>14.30 : 14.55 – Zasady organizacji zawodów, sprawy techniczne (s. 25)</p>
            </li>
            <li>
            <p>15.15 : 17.00 – Zawody próbne (pracownie, Ip. IIUWr)</p>
            </li>
            <li>
            <p>17:00 : 18.00 – Możliwość spotkania z przedstawicielami sponsorów (hall, parter IIUWr)</p>
            </li>
            <li>
            <p>18:00 – Kolacja (I p. IIUWr); Ogłoszenie wyników loterii Google’a</p>

            </li>
        </ul>

        <h3>Sobota, 29 X 2016</h3>

        <ul>
            <li>
            <p>8.30 – Przyjazd zespołów</p>
            </li>
            <li>
            <p>8.45 – Last minute announcements</p>
            </li>
            <li>
            <p>9.30 : 14.30 – Zawody &nbsp;(pracownie, Ip. IIUWr)</p>
            </li>
            <li>
            <p>14.30 : 16.00 – Czas wolny</p>
            </li>
            <li>
            <p>16.00 : 17.00 – Omówienie zadań (s. 25)</p>
            </li>
            <li>
            <p>17.00 – Uroczystość zakończenia (s. 25)</p>
            </li>
            <li>
            <p>18.00 – Pizza Party (Ip. IIUWr)</p>
            </li>
        </ul>

			</div>
    )
}

function Harmonogram2015()
{
    return (
        <div className="main-content harmonogram.html">
        <h2>Harmonogram Zawodów</h2>
        <p>Do <b>4 X 2015</b> – zgłoszenie liczby drużyn (i opiekunów)</p>
        <p> <b>7 X 2015</b> - informacja o liczbie przyjętych drużyn</p>
        <p>Do <b>13 X 2015</b> – imienne zgłoszenie członków drużyn</p>
        <p>Do <b>16 X 2015</b> – dokonanie wpłaty za uczestnictwo w zawodach</p>
        <p>
        </p>

        <h3>Piątek, 23 X 2015</h3>

        <ul>
            <li>
            <p>Przyjazd</p>
            </li>
        </ul>

        <h3>Sobota, 24 X 2015</h3>

        <ul>
            <li>
            <p>8.15 : 9.45 – Rejestracja</p>
            </li>
            <li>
            <p>10.00 – Uroczystość otwarcia (s. 25)</p>
            </li>
            <li>
            <p>10.15 – Wykłady przedstawicieli sponsorów (s. 25)</p>

            <ul className="indent">
                <li>
                <p>10.15 : 10.40 Nokia - Kamil Szatkowski i ​Łukasz Ziobroń - “Modern C++ Tips&amp;Tricks”</p>
                </li>
                <li>
                <p>10.40 : 11.05 Dolby - Łukasz Waksmański - “How we put technology into your device”</p>
                </li>
                <li>
                <p>11.05 : 11.30 Credit Suisse - Artur Tadrala - “Programming challenge!“</p>
                </li>
                <li>
                <p>11.30 : 11.45 Przerwa</p>
                </li>
                <li>
                <p>11.45 : 12.10 Facebook - Dawid Pustułka - “Improving performance of Facebook”</p>
                </li>
                <li>
                <p>12.10 : 12.35 Google - Jarek Kuśmierek - “Google: Future of Computing is in Cloud”</p>
                </li>
                <li>
                <p>12.35 : 13.00 TenSquareGames - Janusz Dziemidowicz - “Krótki przegląd ataków na szyfrowanie w Internecie oraz co się z tym robi”</p>
                </li>
            </ul>
            </li>
            <li>
            <p>13.00 : 14.30 – Obiad (parter IIUWr)</p>
            </li>
            <li>
            <p>14.30 : 14.55 – Zasady organizacji zawodów, sprawy techniczne (s. 25)</p>
            </li>
            <li>
            <p>15.15 : 17.00 – Zawody próbne (pracownie, Ip. IIUWr)</p>
            </li>
            <li>
            <p>17:00 : 18.00 - Możliwość spotkania z przedstawicielami sponsorów (hall, parter IIUWr)</p>
            </li>
            <li>
            <p>18:00 Kolacja (I p. IIUWr); Ogłoszenie wyników loterii Google’a</p>
            </li>
        </ul>

        <h3>Niedziela, 25 X 2015</h3>

        <ul>
            <li>
            <p>8.30 – Przyjazd zespołów</p>
            </li>
            <li>
            <p>8.45 – Last minute announcements</p>
            </li>
            <li>
            <p>9.30 : 14.30 – Zawody &nbsp;(pracownie, Ip. IIUWr)</p>
            </li>
            <li>
            <p>10.00 : 14.00 – Spotkania z przedstawicielami sponsorów (hall, parter IIUWr)</p>
            </li>
            <li>
            <p>14.30 : 16.00 – Czas wolny</p>
            </li>
            <li>
            <p>16.00 : 17.00 – Omówienie zadań (s. 25)</p>
            </li>
            <li>
            <p>17.00 – Uroczystość zakończenia (s. 25)</p>
            </li>
            <li>
            <p>18.00 – Pizza Party (Ip. IIUWr)</p>
            </li>
        </ul>


	
					</div>        
    )
}

function Harmonogram(data)
{
    if (data.year==="2015") return Harmonogram2015();
    if (data.year==="2016") return Harmonogram2016();
    if (data.year==="2017") return Harmonogram2017();
    return Harmonogram2018();
}

export default Harmonogram;
