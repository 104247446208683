function getDates(data)
{      
    if (data.year==="2018") return "26-27 października 2018r.";
    if (data.year==="2017") return "27-28 października 2017r.";
    if (data.year==="2016") return "28-29 października 2016r.";
    if (data.year==="2015") return "24-25 października 2015r.";
    return "none";
}

function getZadan(data)
{    
    if (data.year==="2017") return "8-13";
    if (data.year==="2018") return "8-13";
    return "8-12";
}

function Glowna(year)
{
    return (
    <div className='main-content glowna.html'>
                    
    <h2>Strona Główna</h2>
    <br />
    <h3>O Mistrzostwach</h3>
    <p>
    Akademickie Mistrzostwa Polski w Programowaniu Zespołowym, w skrócie AMPPZ, to najważniejsze zawody w Polsce dla studentów informatyki. Pierwsze mistrzostwa odbyły się w roku 1996 w Poznaniu.
    </p>

    <h3>Zasady</h3>
    <ul className='indent'>
        <li>startują drużyny z polskich uczelni,</li>
        <li>drużyny składają się z <strong>3 zawodników</strong>,</li>
        <li>drużyna ma <strong>5 godzin</strong> na rozwiązanie zadań,</li>
        <li>do rozwiązania jest <strong>{getZadan(year)} zadań</strong>,</li>
        <li>rozwiązania zadań to <strong>programy komputerowe</strong>,</li>
        <li>rozwiązania są oceniane <strong>na żywo</strong> w trakcie zawodów.</li>
    </ul>
    <p>
    Szczegółowe zasady znaleźć można w <a href={window.location.href+"/regulamin"}>regulaminie</a>.
    </p>

    <h3>Tegoroczna Edycja</h3>
    <p>
    Tegoroczne mistrzostwa odbędą się w dniach <strong>{getDates(year)}</strong> na Uniwersytecie Wrocławskim. Dokładny plan znajduje się w <a href="./harmonogram">harmonogramie</a>.
    </p>

    <h3>Finały Światowe</h3>
    <p>
    AMPPZ to krajowa odmiana konkursu <a target="blank" href="http://cm.baylor.edu/welcome.icpc">ICPC</a> (The ACM International Collegiate Programming Contest) - najstarszego i najbardziej prestiżowego konkursu informatycznego na świecie, uznawanego za Akademickie Mistrzostwa Świata w Programowaniu Zespołowym.
    </p>
    </div>
    )
}

export default Glowna;
