import menuimg from "./img/menu.svg"

function isActive(name)
{
    let adr = window.location.href;
    let host = window.location.protocol + "//" + window.location.host + '/';

    if ((name==='glowna' && adr===host) || 
        (name==='glowna' && adr===host+"2018/") || 
        (name==='glowna' && adr===host+"2017/") || 
        (name==='glowna' && adr===host+"2016/") || 
        (name==='glowna' && adr===host+"2015/") || 
        adr.includes(name))
    {
        return 'glowt';
    }
    else
    {
        return '';
    }
}

function getLastLink(data)
{
    let ny = "2017";
    
    if (data.year==="2017/") ny = "2016";
    if (data.year==="2015/") ny = "2016";
    if (data.year==="2016/") ny = "2015";
    if (data.year==="2018/") ny = "2017";
    return (<li><a className={isActive({ny})} href={'/'+ny+'/'}>Amppz {ny}</a></li>);
}

function myFunction()
{
    var x = document.getElementsByClassName("list")[0];
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }}

function Menu(data)
{
    let pref = "./";

    let ll = getLastLink(data);
    let yy = data.year;
    let gl = "/gallery2018/index.html";

    if (yy==="2015/"){ 
        gl = "https://goo.gl/photos/X4DRqTbMADU7rQHo6";
    }
    if (yy==="2016/"){ 
        gl = "/gallery2016/index.html";
    }
    if (yy==="2017/"){ 
        gl = "/gallery2017/index.html";
    }
    //<i className="fa fa-bars">uueeeeeeeeeeeeeeeeu</i>

    return (
        <nav className="menu">
        <button className="icon" onClick={myFunction} >        
        <img src={menuimg} className="menuIcon" alt="menu icon" />        
        </button>
        <ul className="list">
        <li><a className={isActive('glowna')}         href={pref+'.'}>Strona Główna</a></li>
        <li><a className={isActive('miejsce')}        href={pref+'miejsce'}>Miejsce zawodów</a></li>
        <li><a className={isActive('zakwaterowanie')} href={pref+'zakwaterowanie'}>Zakwaterowanie</a></li>
        <li><a className={isActive('oplaty')}         href={pref+'oplaty'}>Opłaty</a></li>
        <li><a className={isActive('harmonogram')}    href={pref+'harmonogram'}>Harmonogram</a></li>
        <li><a className={isActive('rejestracja')}    href={pref+'rejestracja'}>Rejestracja drużyn</a></li>
        <li><a className={isActive('tresci')}         href={pref+'tresci'}>Treści zadań</a></li>
        <li><a className={isActive('rozwiazania')}    href={pref+'rozwiazania'}>Prezentacja rozwiązań</a></li>        
        <li><a className={isActive('gallery')}        href={gl}>Galeria</a></li>        
        <li><a className={isActive('ranking')}        href={pref+'ranking'}>Ostateczny ranking</a></li>
        <li><a className={isActive('nagrody')}        href={pref+'nagrody'}>Nagrody</a></li>
        <li><a className={isActive('regulamin')}      href={pref+'regulamin'}>Regulamin</a></li>
        <li><a className={isActive('kontakt')}        href={pref+'kontakt'}>Kontakt</a></li>
        {ll}
        </ul>
        </nav>    
    )
}

export default Menu;
