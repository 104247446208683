function Rejestracja2018()
{
    return (
        <div className="main-content rejdruzyn.html">
        <h2>Rejestracja drużyn</h2>
        <p>
        <strong>Rejestracji drużyn można dokonywać:</strong>
        </p>
        <ul>
        <li>poprzez system <a href="https://icpc.baylor.edu/regionals/finder/amppz-2018" target="_blank" rel='noreferrer'>ICPC</a> (preferowany sposób)</li>
        <li>wysyłając zgłoszenie na adres organizatora - <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a></li>
        </ul>
		</div>        
    )
}

function Rejestracja2017()
{
    return (
            <div className="main-content rejdruzyn.html">
			<h2>Rejestracja drużyn</h2>
            <p>
            <strong>Rejestracji drużyn można dokonywać:</strong>
            </p><ul>
            <li>poprzez system <a href="https://icpc.baylor.edu/regionals/finder/amppz-2017" rel="noreferrer" target="_blank">ICPC</a> (preferowany sposób)</li>
            <li>wysyłając zgłoszenie na adres organizatora - <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a></li>
            </ul>
			</div>
    )
}

function Rejestracja2016()
{
    return (
            <div className="main-content rejdruzyn.html">
            <h2>Rejestracja drużyn</h2>
            <p>
            <strong>Rejestracji drużyn można dokonywać:</strong>
            </p><ul>
            <li>poprzez system <a href="https://icpc.baylor.edu/regionals/finder/amppz-2016" rel="noreferrer" target="_blank">ICPC</a> (preferowany sposób)</li>
            <li>wysyłając zgłoszenie na adres organizatora - <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a></li>
            </ul>
			</div>
    )
}

function Rejestracja2015()
{
    return (
        <div className="main-content rejdruzyn.html">
        <h2>Rejestracja drużyn</h2>
        <p>
        <strong>Rejestracji drużyn można dokonywać:</strong>
        </p><ul>
        <li>poprzez system <a href="https://icpc.baylor.edu/regionals/finder/amppz-2015" rel="noreferrer" target="_blank">ICPC</a> (preferowany sposób)</li>
        <li>wysyłając zgłoszenie na adres organizatora - <a href="mailto:amppz@cs.uni.wroc.pl">amppz@cs.uni.wroc.pl</a></li>
        </ul>
        </div>
    )
}

function Rejestracja(data)
{
    if (data.year==="2015") return Rejestracja2015();
    if (data.year==="2016") return Rejestracja2016();
    if (data.year==="2017") return Rejestracja2017();
    return Rejestracja2018();       
}

export default Rejestracja;
