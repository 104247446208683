function Nagrody()
{
    return (
        <div className="main-content nagrody.html">
		<p className="huge">Fundatorem nagród jest: </p> 
        <img alt='PKO fundacja' src="/img/pkofundacja.png" />
		</div>        
    )
}

export default Nagrody;
