import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
 // Link
} from "react-router-dom";

import './App.css';
import './main.css';


import Menu from './Menu';
import MainHeader from './MainHeader';
import Sponsors from './Sponsors';
import Footer  from './Footer';
import Glowna  from './Glowna';
import Miejsce from "./Miejsce";
import Zakwaterowanie from "./Zakwaterowanie";
import Oplaty from "./Oplaty";
import Harmonogram from "./Harmonogram";
import Rejestracja from "./Rejestracja";
import Tresci from "./Tresci";
import Rozwiazania from "./Rozwiazania";
import Ranking from "./Ranking";
import Regulamin from "./Regulamin";
import Nagrody from "./Nagrody";
import Kontakt from "./Kontakt";
import Facebook from "./Facebook";

function getYear()
{
  let adr = window.location.href;
  if (adr.includes('2017')) return '2017';
  if (adr.includes('2016')) return '2016';
  if (adr.includes('2015')) return '2015';
  return '2018';
}

function getYearOrEmpty()
{
  let adr = window.location.href;
  //let host = window.location.hostname;
  //console.log(host);
/*
  var count2015 = (adr.match(/2015/g) || []).length;
  var count2016 = (adr.match(/2016/g) || []).length;
  var count2017 = (adr.match(/2017/g) || []).length;
  var count2018 = (adr.match(/2018/g) || []).length;
  console.log(count2015);
  console.log(count2016);
  console.log(count2017);
  console.log(count2018);
*/
  //if (count2015>=1 || count2016>=1 || count2017>1)
  //{
    //return '/';
  //}
  
  if (adr.includes('2015')) return '2015/';
  if (adr.includes('2016')) return '2016/';
  if (adr.includes('2017')) return '2017/';
  if (adr.includes('2018')) return '2018/';
  return '';
}

function getDate()
{
  let adr = window.location.href;
  if (adr.includes('2015')) return "24-25 X 2015";
  if (adr.includes('2016')) return "28-29 X 2016";
  if (adr.includes('2017')) return "27-28 X 2017";
                            return "26-27 X 2018";
}

function App() {
  return (
    <div>
      <MainHeader text={getDate()}/>  
      
      <section className='content-wrapper'>
      <Menu className='topnav' year={getYearOrEmpty()}/>  
      <section className='m-content'>

        <Router>
        <Switch>
          <Route path="*/miejsce">
            <Miejsce />
          </Route>
          <Route path="*/zakwaterowanie">
            <Zakwaterowanie year={getYear()}/>
          </Route>
          <Route path="*/oplaty">
            <Oplaty year={getYear()}/>
          </Route>
          <Route path="*/harmonogram">
            <Harmonogram year={getYear()} />
          </Route>
          <Route path="*/rejestracja">
            <Rejestracja year={getYear()} />            
          </Route>
          <Route path="*/tresci">
            <Tresci year={getYear()} />
          </Route>
          <Route path="*/rozwiazania">
            <Rozwiazania year={getYear()} />
          </Route>
          <Route path="*/ranking">
            <Ranking year={getYear()} />
          </Route>
          <Route path="*/regulamin">
            <Regulamin year={getYear()} />
          </Route>
          <Route path="*/nagrody">
            <Nagrody />
          </Route>
          <Route path="*/kontakt">
            <Kontakt />
          </Route>
          <Route path="/">
            <Glowna year={getYear()}/>
          </Route>
        </Switch>
        </Router>

        <Sponsors className='sponsors' year={getYear()}/>
        <Facebook />

      </section>
      </section>
      
      <Footer />
    </div>
    );  
}

export default App;
